<div *ngIf="data">
  <app-client-side-data-table
    [id]="'documents-table-id'"
    [selectFieldName]="'uniqueId'"
    [data]="data"
    [columns]="columns"
    [sort]="sort"
    [eventService]="documentsListEventsService"
    [refreshIt]="refreshDataTable"
  ></app-client-side-data-table>
</div>
