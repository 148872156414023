import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  combineLatest,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors';
import { AppState } from 'src/app/core/store/state';
import { isPresent } from 'src/app/core/utils/isPresent';
import { ReportingApiService } from 'src/app/shared/services/reporting-api.service';
import { DialogsActions } from '../../dialogs/store/actions';
import { NotificationsActions } from '../../notifications/store/actions';
import { GetDocumentDto, GetDocumentDtoExtended } from '../models/documents.model';
import { DocumentsApiService } from '../services/documents-api.service';
import { DocumentsActions } from './actions';

@Injectable()
export class FeatureEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private documentsApiService: DocumentsApiService,
    private reportingApiService: ReportingApiService,
    private dialogRef: MatDialog,
    private translocoService: TranslocoService,
  ) {}

  getDocuments$ = createEffect(() =>
    combineLatest([this.actions$.pipe(ofType(DocumentsActions.getDocuments))]).pipe(
      map(() => {
        return DocumentsActions.loadDocuments();
      }),
    ),
  );

  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.loadDocuments),
      switchMap(() => {
        return this.documentsApiService.getDocuments$().pipe(
          withLatestFrom(this.store.select(selectUser).pipe(filter(isPresent))),
          map(([documents, user]) => {
            const docsExtended: GetDocumentDtoExtended[] = [];
            documents.map((document: GetDocumentDto) => {
              docsExtended.push({
                ...document,
                counterparties: document.parties,
                myPosition: document.userPositionId,
              });
              return document;
            });
            return DocumentsActions.setDocuments({ documents: docsExtended });
          }),
          catchError((response) => {
            return of(DocumentsActions.loadDocumentsFail());
          }),
        );
      }),
    ),
  );

  createDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.createDocument),
      mergeMap((props) =>
        this.documentsApiService.createDocument$(props.model).pipe(
          map((document) => DocumentsActions.createDocumentSuccess({ document })),
          catchError((response) => {
            return of(DocumentsActions.createDocumentFail(), DocumentsActions.getDocuments());
          }),
        ),
      ),
    ),
  );

  createDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.createDocumentSuccess),
      map((props) => {
        this.store.dispatch(DocumentsActions.closeCreatingDocumentModal());
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.docAdded', {
              name: props.document.name,
            }),
            action: 'x',
          }),
        );

        this.dialogRef.closeAll();
        return DocumentsActions.getDocuments();
      }),
    ),
  );

  createDocumentCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.createDocumentCancel),
      map((props) => {
        return DocumentsActions.createDocumentCancelled();
      }),
    ),
  );

  updateDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.updateDocuments),
      mergeMap((props) => {
        return this.documentsApiService.batchUpdateDocuments$(props.documents).pipe(
          map(() => DocumentsActions.updateDocumentsSuccess()),
          catchError((response) => {
            return of(DocumentsActions.getDocuments(), DocumentsActions.updateDocumentsFail());
          }),
        );
      }),
    ),
  );

  updateDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.updateDocumentsSuccess),
      map(() => {
        return DocumentsActions.getDocuments();
      }),
    ),
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.deleteDocument),
      map((props) => {
        return DialogsActions.showDialog({
          data: {
            actionsKeys: ['yes', 'no'],
            content: '',
            title: this.translocoService.translate('modalBody.confirmDeleteDocument'),
          },
          callback: (action) => {
            if (action === 'yes') {
              return this.store.dispatch(
                DocumentsActions.deleteDocumentConfirmed({ uniqueId: props.uniqueId }),
              );
            } else {
              return of(
                DialogsActions.setRef({ ref: null }),
                DocumentsActions.deleteDocumentCancel(),
              );
            }
          },
        });
      }),
    ),
  );

  deleteDocumentConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.deleteDocumentConfirmed),
      mergeMap((props) => {
        return this.documentsApiService.deleteDocument$(props.uniqueId).pipe(
          map(() => DocumentsActions.deleteDocumentSuccess()),
          catchError((response) => {
            return of(
              DialogsActions.setRef({ ref: null }),
              DocumentsActions.getDocuments(),
              DocumentsActions.deleteDocumentFail(),
            );
          }),
        );
      }),
    ),
  );

  deleteDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.deleteDocumentSuccess),
      map(() => {
        return DocumentsActions.getDocuments();
      }),
    ),
  );

  deleteDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.deleteDocuments),
      map((props) => {
        return DialogsActions.showDialog({
          data: {
            actionsKeys: ['yes', 'no'],
            content: '',
            title: this.translocoService.translate('modalBody.confirmDeleteDocuments'),
          },
          callback: (action) => {
            if (action === 'yes') {
              return this.store.dispatch(
                DocumentsActions.deleteDocumentsConfirmed({ uniqueIds: props.uniqueIds }),
              );
            } else {
              return of(
                DialogsActions.setRef({ ref: null }),
                DocumentsActions.deleteDocumentsCancel(),
              );
            }
          },
        });
      }),
    ),
  );

  deleteDocumentsConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.deleteDocumentsConfirmed),
      mergeMap((props) => {
        return this.documentsApiService.batchDeleteDocument$(props.uniqueIds).pipe(
          map(() => DocumentsActions.deleteDocumentsSuccess()),
          catchError((response) => {
            return of(
              DialogsActions.setRef({ ref: null }),
              DocumentsActions.getDocuments(),
              DocumentsActions.deleteDocumentsFail(),
            );
          }),
        );
      }),
    ),
  );

  deleteDocumentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.deleteDocumentsSuccess),
      map(() => {
        return DocumentsActions.getDocuments();
      }),
    ),
  );

  loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.loadDocument),
      switchMap(({ uniqueId }) => {
        return this.documentsApiService.getDocumentById$(uniqueId).pipe(
          map((document) => DocumentsActions.setDocument({ document })),
          catchError((response) => {
            return of(DocumentsActions.loadDocumentFail());
          }),
        );
      }),
    ),
  );

  editDocumentCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.editDocumentCancel),
      map((props) => {
        return DocumentsActions.editDocumentCancelled();
      }),
    ),
  );

  editDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.editDocument),
      mergeMap((props) =>
        this.documentsApiService.updateDocument$(props.model, props.uniqueId).pipe(
          map((document) => DocumentsActions.editDocumentSuccess({ document })),
          catchError((response) => {
            return of(DocumentsActions.editDocumentFail(), DocumentsActions.getDocuments());
          }),
        ),
      ),
    ),
  );

  editDocumentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.editDocumentSuccess),
      map((props) => {
        this.store.dispatch(DocumentsActions.closeEditingDocumentModal());
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.docUpdated', {
              name: props.document.name,
            }),
            action: 'x',
          }),
        );

        this.dialogRef.closeAll();
        return DocumentsActions.getDocuments();
      }),
    ),
  );

  sendReportCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.sendReportCancel),
      map((props) => {
        return DocumentsActions.sendReportCancelled();
      }),
    ),
  );

  sendReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.sendReport),
      mergeMap((props) =>
        this.reportingApiService.sendReport$(props.report).pipe(
          map((response) => DocumentsActions.sendReportSuccess({ report: props.report, response })),
          catchError((response) => {
            return of(DocumentsActions.sendReportFail());
          }),
        ),
      ),
    ),
  );

  sendReportSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.sendReportSuccess),
      map((props) => {
        // this.store.dispatch(DocumentsActions.closeSendingReportModal());
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.reportSent', {
              recipients: props.report.recipients.join(', '),
            }),
            action: 'x',
          }),
        );

        this.dialogRef.closeAll();
        return DocumentsActions.closeSendingReportModal();
      }),
    ),
  );
}
