@if (form) {
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title pull-left">{{ title }}</h5>
      <mat-icon (click)="onCancel()" class="">{{ 'close' }}</mat-icon>
    </div>

    <div class="modal-body" [formGroup]="form">
      <div class="row">
        <div class="col">
          <app-alert [type]="alertType.Info">
            {{ 'modalBody.sendReportInfo' | transloco }}
          </app-alert>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          {{ 'labels.recipients' | transloco }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <app-checkbox formControlName="sendToMe" id="sendToMe">
            {{ 'labels.sendToMe' | transloco: { email: currentUser?.email || '' } }}
          </app-checkbox>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <app-checkbox formControlName="sendToOthers" id="sendToOthers">
            {{ 'labels.sendToOthers' | transloco }}
          </app-checkbox>
        </div>
      </div>
      <div class="row mt-2" *ngIf="isSendToOthers">
        <div class="col">
          <app-input
            placeholder="{{ 'placeholders.sendToOthersEmails' | transloco }}"
            [highlightAsError]="isSendToOthersEmailsInvalid"
            formControlName="sendToOthersEmails"
          ></app-input>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <app-button
        [type]="btnTypes.Flat"
        label="{{ 'buttons.send' | transloco }}"
        [disabled]="!isFormValid || !!sending"
        [size]="btnSizes.Basic"
        (onClick)="onConfirm()"
      ></app-button>
      <app-button
        [type]="btnTypes.Stroked"
        label="{{ 'buttons.cancel' | transloco }}"
        [disabled]="!!sending"
        [size]="btnSizes.Basic"
        (onClick)="onCancel()"
      ></app-button>
    </div>
  </div>
}
