export interface DocumentDto {
  uniqueId: string;
  name: string;
  type: number;
  lastModifiedAt: string;
  lastModifiedBy: string;
  userPositionId: number;
  parties: number[];
  status: number;
  languageId: number;
}

export interface BatchUpdateDocumentDto {
  uniqueId: string;
  type: number;
  userPositionId: number;
  parties: number[];
  status: number;
  languageId: number;
}

export interface GetDocumentDto {
  uniqueId: string;
  name: string;
  sharedLink: string;
  directLink: string;
  type: number;
  lastModifiedAt: string;
  lastModifiedBy: string;
  userPositionId: number;
  parties: number[];
  status: number;
  languageId: number;
}

export interface GetDocumentDtoExtended extends GetDocumentDto {
  counterparties: number[];
  myPosition: number;
}

export interface DocumentCommonDto {
  base64Content?: string;
  properties: DocumentPropertiesDto;
}

export interface DocumentPropertiesDto {
  name: string;
  type: number;
  userPositionId: number;
  parties: number[];
  status: number;
  languageId: number;
}

export interface DocumentSearchModel {
  documentName?: string;
  documentType?: number[];
  counterparty?: number[];
  position?: number[];
  status?: number[];
  languageId: number[];
}

export enum DocumentOperation {
  Review = 'review',
  Query = 'query',
  AddClauses = 'addClauses',
}
