import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, debounceTime, filter, tap } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { DocSearchCriterionsFactory } from 'src/app/core/factories/doc-search-criterions.factory';
import {
  Criterion,
  EventsServiceAction,
  EventsServiceData,
  SearchItemModel,
} from 'src/app/core/services/base-event-service';
import { selectUser } from 'src/app/core/store/selectors';
import {
  getDocumentsFilterConditionsFromModel,
  getUpdatedDocsByBulkEditOperation,
} from 'src/app/core/utils/documents.utils';
import { getFiltersFormModel } from 'src/app/core/utils/filter.utils';
import { isPresent } from 'src/app/core/utils/isPresent';
import { PartiesApiService } from 'src/app/modules/counterparties/services/parties-api.service';
import { selectDocTypes } from 'src/app/modules/doc-types/store/selectors';
import { AppBulkEditMultipleValuesPropertyComponent } from 'src/app/shared/components/bulk-edit/app-bulk-edit-multiple-values-property/app-bulk-edit-multiple-values-property.component';
import {
  AppBulkEditSingleValuePropertyComponent,
  BulkEditValueModel,
} from 'src/app/shared/components/bulk-edit/app-bulk-edit-single-value-property/app-bulk-edit-single-value-property.component';
import { DocumentStatusesApiService } from 'src/app/shared/services/document-statuses-api.service';
import { PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { selectContentLanguages } from 'src/app/shared/store/selectors';
import { isPlural } from 'src/app/shared/utils';
import { DocumentSearchModel, GetDocumentDtoExtended } from '../../models/documents.model';
import { DocumentsActions } from '../../store/actions';
import { fromDocuments } from '../../store/selectors';
import { DocumentsListEventsService } from '../documents-list/documents-list-events.service';
import { DocUploadModalComponent } from '../modals/doc-upload-modal/doc-upload-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public criterions: Criterion[] = this.docSearchCriterionsFactory.getAllItemsAsArray();
  public conditions: SearchItemModel[] | undefined;
  public form: FormGroup | undefined;
  public dataRecieved: boolean = false;
  public contentLanguages: any[] = [];
  public selectedIds: string[] = [];
  public filteredDocuments: GetDocumentDtoExtended[] = [];
  public allDocuments: GetDocumentDtoExtended[] = [];

  constructor(
    private readonly store$: Store,
    private titleService: Title,
    private bsModalService: BsModalService,
    private documentsListEventsService: DocumentsListEventsService,
    private docSearchCriterionsFactory: DocSearchCriterionsFactory,
    private partiesApiService: PartiesApiService,
    private positionsApiService: PositionsApiService,
    private documentStatusesApiService: DocumentStatusesApiService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public docTypeItems: any;
  public docStatusItems: any;
  public positionItems: any;
  public partyItems: any;

  ngOnInit() {
    this.waitUserReadyAndStart();
  }

  ngOnDestroy(): void {
    this.resetSelections();
  }

  waitUserReadyAndStart(): void {
    this.store$
      .select(selectUser)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((user) => {
        this.startInitialization();
      });
  }

  startInitialization(): void {
    this.store$.dispatch(DocumentsActions.getDocuments());

    this.initForm();

    this.store$
      .select(fromDocuments.selectDocuments)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((data) => {
        this.allDocuments = data;
        this.dataRecieved = !!data;
        this.cdr.markForCheck();

        setTimeout(() => {
          this.resetSelections();
          this.checkAndRestoreFilters();
        });
      });

    this.titleService.setTitle('AutoLex | Documents');
    this.documentsListEventsService.setFilterCriterions(this.criterions);
  }

  checkAndRestoreFilters() {
    const filters = this.documentsListEventsService.getFilters();

    if (filters?.length > 0) {
      const model = getFiltersFormModel(filters);
      this.form?.patchValue(model);
    } else {
      this.documentsListEventsService.onFilter([]);
    }
  }

  initForm() {
    combineLatest([
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
      this.store$.select(selectDocTypes).pipe(filter(isPresent)),
      this.partiesApiService.getParties$(), // todo: refactor
      this.positionsApiService.getPositions$(), // todo: refactor
      this.documentStatusesApiService.getDocumentStatuses$(), // todo: refactor
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([contentLanguages, docTypes, parties, postitions, docStatuses]) => {
        this.docTypeItems = docTypes;
        this.partyItems = parties;
        this.positionItems = postitions;
        this.docStatusItems = docStatuses;
        this.contentLanguages = contentLanguages;

        this.form = this.fb.group({
          documentName: [''],
          documentType: [null],
          counterparty: [null],
          position: [null],
          status: [null],
          languageId: [null],
        });

        this.initSubscriptions();
        this.cdr.markForCheck();
      });
  }

  initSubscriptions(): void {
    this.form?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap((model: DocumentSearchModel) => {
          this.conditions = getDocumentsFilterConditionsFromModel(
            model,
            this.docSearchCriterionsFactory,
          );
        }),
      )
      .subscribe((value) => {
        this.documentsListEventsService.resetPagination();

        if (!!this.conditions) {
          this.documentsListEventsService.onFilter(this.conditions || []);
        }

        this.checkAndResetSelectAll();
      });

    this.documentsListEventsService
      .getSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: EventsServiceData) => {
        switch (data.action) {
          case EventsServiceAction.Select: {
            this.selectedIds = data.id;
            this.cdr.markForCheck();
            break;
          }
        }
      });

    this.documentsListEventsService
      .getDataSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: any[]) => {
        this.filteredDocuments = data; // filtered
        this.cdr.markForCheck();
      });
  }

  checkAndResetSelectAll() {
    if (this.conditions?.length === 0 && this.selectedIds.length === 0) {
      setTimeout(() => {
        this.resetSelections();
      });
    }
  }

  clearFilters(): void {
    this.form?.reset();
    this.cdr.markForCheck();

    if (this.selectedIds.length === 0) {
      setTimeout(() => {
        this.resetSelections();
      });
    }
  }

  bulkDelete(): void {
    this.store$.dispatch(DocumentsActions.deleteDocuments({ uniqueIds: this.selectedIds }));
  }

  bulkEditDocType(): void {
    const modal = this.bsModalService.show(AppBulkEditSingleValuePropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditDocumentType',
        countItemsWillBeUpdatedKey: this.textCountDocsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.documentType,
        mainItemIdKey: constants.fields.documentId,
        mainItems: this.allDocuments,
        options: this.docTypeItems,
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateDocsByBulkEditOperation(model));
  }

  bulkEditDocPosition(): void {
    const modal = this.bsModalService.show(AppBulkEditSingleValuePropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditDocumentPosition',
        countItemsWillBeUpdatedKey: this.textCountDocsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.documentUserPosition,
        mainItemIdKey: constants.fields.documentId,
        mainItems: this.allDocuments,
        options: this.positionItems,
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateDocsByBulkEditOperation(model));
  }

  bulkEditDocCounterparties(): void {
    const modal = this.bsModalService.show(AppBulkEditMultipleValuesPropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditDocumentCounterparties',
        countItemsWillBeUpdatedKey: this.textCountDocsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.documentCounterparties,
        mainItemIdKey: constants.fields.documentId,
        mainItems: this.allDocuments,
        options: this.partyItems,
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateDocsByBulkEditOperation(model));
  }

  bulkEditDocStatus(): void {
    const modal = this.bsModalService.show(AppBulkEditSingleValuePropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditDocumentStatus',
        countItemsWillBeUpdatedKey: this.textCountDocsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.documentStatus,
        mainItemIdKey: constants.fields.documentId,
        mainItems: this.allDocuments,
        options: this.docStatusItems,
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateDocsByBulkEditOperation(model));
  }

  bulkEditDocLanguage(): void {
    const modal = this.bsModalService.show(AppBulkEditSingleValuePropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditDocumentLanguage',
        countItemsWillBeUpdatedKey: this.textCountDocsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.documentLanguage,
        mainItemIdKey: constants.fields.documentId,
        mainItems: this.allDocuments,
        options: this.contentLanguages,
        optionLabelKey: 'displayName',
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateDocsByBulkEditOperation(model));
  }

  updateDocsByBulkEditOperation(model: BulkEditValueModel): void {
    let documentsWithUpdates = getUpdatedDocsByBulkEditOperation(this.allDocuments, model);

    this.store$.dispatch(DocumentsActions.updateDocuments({ documents: documentsWithUpdates }));
  }

  selectAll(): void {
    this.documentsListEventsService.selectAll$.next(true);
  }

  resetSelections(): void {
    this.documentsListEventsService.resetSelections$.next(true);
    this.selectedIds = [];
    this.cdr.markForCheck();
  }

  addDocument(): void {
    this.bsModalService.show(DocUploadModalComponent, {
      initialState: {},
      class: 'modal-xl',
    });
  }

  get isAnySelected(): boolean {
    return this.selectedIds && this.selectedIds.length > 0;
  }

  get filteredDocumentsCount(): number {
    return this.filteredDocuments?.length || 0;
  }

  get selectedDocumentsCount(): number {
    return this.selectedIds?.length || 0;
  }

  get textCountOfSelectedDocs(): string {
    return isPlural(this.selectedDocumentsCount)
      ? 'text.countOfSelectedDocs'
      : 'text.countOfSelectedDocsSingle';
  }

  get textButtonsSelectAllDocs(): string {
    return isPlural(this.filteredDocumentsCount)
      ? 'buttons.selectAllDocs'
      : 'buttons.selectAllDocsSingle';
  }

  get textCountDocsWillBeUpdated(): string {
    return isPlural(this.selectedDocumentsCount)
      ? 'text.countDocsWillBeUpdated'
      : 'text.countDocsWillBeUpdatedSingle';
  }
}
