import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { BatchUpdateDocumentDto, DocumentCommonDto, GetDocumentDto } from '../models/documents.model';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService extends ApiService {
  getDocuments$(): Observable<GetDocumentDto[]> {
    return this.get('/documents');
  }

  createDocument$(document: DocumentCommonDto): Observable<GetDocumentDto> {
    return this.put('/documents', document);
  }

  updateDocument$(document: DocumentCommonDto, uniqId: string): Observable<GetDocumentDto> {
    return this.post(`/documents/${uniqId}`, document);
  }

  batchUpdateDocuments$(documents: BatchUpdateDocumentDto[]): Observable<GetDocumentDto[]> {
    return this.post(`/documents/batch-update`, documents);
  }

  getDocumentById$(uniqId: string): Observable<GetDocumentDto> {
    return this.get(`/documents/${uniqId}`);
  }

  deleteDocument$(uniqueId: string): Observable<any> {
    return this.delete(`/documents/${uniqueId}`);
  }

  batchDeleteDocument$(uniqueIds: string[]): Observable<any> {
    return this.post(`/documents/batch-delete`, uniqueIds);
  }
}

export const apiDocumentsProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiDocuments.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiDocuments.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiDocuments.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiDocuments.scopes.write],
  },
];
